import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import ImageMapper from 'react-image-mapper';

import BookingForm from './../components/booking-form';
import { FIRST_FLOOR_AREA } from './../config/constants';
import Footer from './../components/footer';
import Layout from './../components/layout';
import NavSection from './../components/nav-section';

export class FirstFloor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moveMsg: '',
    };
  }

  load() {
    this.setState({ msg: 'Interact with image !' });
  }

  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const {
      floor,
      slug,
      header,
      link,
      linkText,
      linkImage,
      about,
      bookingLink,
      bookingText,
      imageMapSource,
      imageMapWidth,
      imageMap,
      details
    } = get(this, 'props.data.allContentfulFloors.edges')[0].node;

    const footer = get(this, 'props.data.allContentfulFooter.edges')

    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;

    return (
      <Layout color="lightBrown">
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title={floor} />
        <div className="container">
          <div className="gallery-header">
            <h1>{header}</h1>
            <span className="font-gtaM">{floor}</span>
            {about &&
              <p className="font-bigcas">{about.about}</p>
            }
          </div>
        </div>
        <section className="container">
          <div className="floor-map">
            <div className="mini-map">
              <Link to={link} title={linkText}>
                <img src={linkImage.file.url} alt="" />
              </Link>
            </div>
            <div className="map">
              <ImageMapper
                src={imageMapSource.file.url}
                map={JSON.parse(imageMap.internal.content)}
                width={imageMapWidth}
                onLoad={() => this.load()}
              />
            </div>
          </div>
        </section>
        <section className="container">
          <div className="venue-whole text-center mb80">
            <div className="inclusions mt-5 mb-5">
              <h4 className="font-gtaM">{details}</h4>
            </div>
            {}
            {bookingLink &&
              <a href={bookingLink}>
                <button className="btn">{bookingText}</button>
              </a>
            }
          </div>
        </section>
        <BookingForm floor={slug} bookingForm={bookingForm}/>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default FirstFloor;

export const pageQuery = graphql`
  query Floor($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulFloors(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          floor
          slug
          header
          link
          linkText
          linkImage {
            file {
              url
            }
          }
          about {
            about
          }
          bookingLink
          bookingText
          imageMapSource {
            file {
              url
            }
          }
          imageMapWidth
          imageMap {
            internal {
              content
            }
          }
          details
        }
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
